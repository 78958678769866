import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import feather from 'vue-icon'
import './main.scss'
import VueClipboard from 'vue-clipboard2'
import VueFlashMessage from 'vue-flash-message'

require('vue-flash-message/dist/vue-flash-message.min.css')

Vue.use(feather, {
  name: 'v-icon',
  props: {
    baseClass: {
      type: String,
      default: 'v-icon'
    },
    classPrefix: {
      type: String,
      default: 'v-icon-'
    }
  }
})

Vue.use(VueClipboard)
Vue.use(VueFlashMessage)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
