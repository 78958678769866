<template>
  <div v-if="!apiError" :class="`Screen ${company.name}`">
    <flash-message transitionIn="flash-transition" class="Flash"></flash-message>

    <header class="Header">
      <img v-if="company.name == 'Vitta'" class="Header-logo" src="../assets/img/logo-vitta.svg" alt="Vitta">
      <img v-else class="Header-logo" src="../assets/img/logo-bild.svg" alt="Bild">
    </header>

    <div class="Content">
      <div class="Person">
        <div class="Person-row">
          <figure v-if="responseApi.foto" class="Person-image">
            <img data-photo :src="responseApi.foto" alt="Foto do usuário">
          </figure>

          <figure v-else class="Person-image">
            <img data-photo src="../assets/img/user.svg" alt="Foto do usuário">
          </figure>
          <h3 class="Person-post">Parceiro de vendas</h3>
        </div>

        <div>
          <div class="Person-name"><strong>{{ responseApi.nome }}</strong></div>
          <div class="Person-mail">
            {{ responseApi.email }}
            <button
              v-clipboard:copy="responseApi.email"
              v-clipboard:success="onCopy"
              class="Copy"
              title="Copiar e-mail">
              <v-icon name="copy" base-class="Copy-icon"></v-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="Infos">
        <div class="Infos-item" v-if="responseApi.imobiliaria_endereco && responseApi.imobiliaria_complemento">
          <a class="Info" target="_blank" :href="`https://waze.com/ul?q=${responseApi.imobiliaria_endereco}${responseApi.imobiliaria_complemento}`">
            <div class="Button Info-button">
              <icon-base class="Button-icon" width="23" height="22" icon-name="waze"><icon-waze /></icon-base>
            </div>
            <div class="Info-content">
              <div class="Info-title">{{ responseApi.imobiliaria }}</div>
              <div class="Info-description">
                <div>{{ responseApi.imobiliaria_endereco }}</div>
                <div>{{ responseApi.imobiliaria_complemento }}</div>
              </div>
            </div>
          </a>
        </div>

        <div class="Infos-item" v-if="responseApi.empreendimento_endereco && responseApi.nome">
          <a class="Info" target="_blank" :href="`https://www.google.com/maps/search/?api=1&query=${responseApi.empreendimento_endereco}`">
            <div class="Button Info-button">
              <v-icon name="map-pin" base-class="Button-icon"></v-icon>
            </div>
            <div class="Info-content">
              <div class="Info-title">{{ responseApi.empreendimento_nome }}</div>
              <div class="Info-description">{{ responseApi.empreendimento_endereco }}</div>
            </div>
          </a>
        </div>

        <div class="Infos-item">
          <a class="Info" target="_blank" :href="company.url">
            <div class="Button Info-button">
              <v-icon name="globe" base-class="Button-icon"></v-icon>
            </div>
            <div class="Info-content">
              <div class="Info-description">{{ company.url }}</div>
            </div>
          </a>
        </div>

        <div class="Infos-item" v-if="responseApi.telefone">
          <a class="Info" target="_blank" :href="`tel:${responseApi.telefone}`">
            <div class="Button Info-button">
              <v-icon name="phone" base-class="Button-icon"></v-icon>
              </div>
            <div class="Info-content">
              <div class="Info-description">{{ responseApi.telefone }}</div>
            </div>
          </a>
        </div>

        <div class="Infos-item" v-else-if="responseApi.whats">
          <a class="Info" target="_blank" :href="`tel:${responseApi.whats}`">
            <div class="Button Info-button">
              <v-icon name="phone" base-class="Button-icon"></v-icon>
              </div>
            <div class="Info-content">
              <div class="Info-description">{{ responseApi.whats }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <footer class="Footer">
      <div class="Footer-row">
        <div v-if="responseApi.facebook">
          <a class="Button Footer-button" target="_blank" :href="`${responseApi.facebook}`">
            <v-icon name="facebook" base-class="Button-icon"></v-icon>
          </a>
        </div>

        <div v-if="responseApi.email">
          <a class="Button Footer-button" :href="`mailto:${responseApi.email}`">
            <v-icon name="mail" base-class="Button-icon"></v-icon>
          </a>
        </div>

        <div v-if="responseApi.whats">
          <a class="Button Footer-button" target="_blank" :href="`https://api.whatsapp.com/send?phone=55${responseApi.whats}&text=Olá ${responseApi.nome}!`">
            <icon-base class="Button-icon" width="19" height="19" icon-name="whatsapp"><icon-whatsapp /></icon-base>
          </a>
        </div>

        <div v-if="responseApi.uber">
          <a class="Button Footer-button" target="_blank" :href="`${responseApi.uber}`">
            <icon-base class="Button-icon" width="24" height="8" icon-name="uber"><icon-uber /></icon-base>
          </a>
        </div>
      </div>
    </footer>
  </div>

  <div v-else class="Screen Screen--error">
    <h1 class="Screen-title">
      <div>Desculpe</div>
      <div>Não encontramos um cartão.</div>
      <div>Por favor, verifique o endereço.</div>
    </h1>
  </div>
</template>

<script>
import IconBase from '../components/IconBase.vue'
import IconUber from '../components/icons/IconUber.vue'
import IconWaze from '../components/icons/IconWaze.vue'
import IconWhatsapp from '../components/icons/IconWhatsapp.vue'

export default {
  components: {
    IconBase,
    IconUber,
    IconWhatsapp,
    IconWaze
  },

  data () {
    return {
      apiError: false,
      baseClass: 'v-icon',
      company: {
        name: 'Bild',
        url: 'https://www.bild.com.br/'
      },
      errorMessage: '',
      footerResponse: {},
      responseApi: {},
      slug: ''
    }
  },

  created () {
    this.setSlug()
    this.fetch()
    this.setCompany()
  },

  methods: {
    async fetch () {
      try {
        const response = await fetch('https://gc.bild.com.br/v-card/' + this.slug)
        this.responseApi = await response.json()

        if (!response.ok) {
          throw this.responseApi
        }
      } catch (error) {
        this.errorMessage = error.message
        this.apiError = true
      }
    },

    setCompany () {
      const url = window.location.hostname
      if (url.search('vitta') !== -1) {
        this.company.name = 'Vitta'
        this.company.url = 'https://vittaresidencial.com.br/'
      }
    },

    setSlug () {
      this.slug = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    },

    onCopy () {
      this.flash('Copiado com sucesso', 'success', {
        timeout: 3000
      })
    }

  }
}
</script>

<style lang="scss">
  $bild: #EC7102;
  $vitta: #002AC8;
  $text-color: #161616;
  $background-primary: #FAFAFA;
  $background-secondary: #E6E5D0;
  $radius: 20px;

  .v-icon,
  .custom-icon {
      width: 24px;
  }

  #app {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Bild {
    $company-color: $bild;
    $background-secondary: #E6E5D0;

    .Header {
      background-color: $company-color;
    }

    .Person {
      &-image {
        background-color: $background-secondary;
      }
    }

    .Info {
      &::before {
        background-color: $company-color;
      }
    }

    .Button {
      background-color: $background-secondary;
    }

    .Footer {
      background-color: $company-color;
    }
  }

  .Vitta {
    $company-color: $vitta;
    $background-secondary: #D0D4E6;

    .Header {
      background-color: $company-color;
    }

    .Person {
      &-image {
        background-color: $background-secondary;
      }
    }

    .Info {
      &::before {
        background-color: $company-color;
      }
    }

    .Button {
      background-color: $background-secondary;
    }

    .Footer {
      background-color: $company-color;
    }
  }

  .Screen {
    background-color: $background-primary;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
    margin: 0 auto;

    &-title {
      font-size: 20px;
    }

    &--error {
      justify-content: center;
      text-align: center;
    }
  }

  .Header {
    align-items: center;
    background-image: url("../assets/img/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 0 0 $radius $radius;
    display: flex;
    height: 133px;
    justify-content: flex-end;
    max-width: 100%;
    padding: 0 50px;

    &-logo {
      width: 102px;
    }
  }

  .Person {
    margin-top: -40px;

    &-row {
      align-items: flex-end;
      display: flex;
      flex-direction: row;
    }

    &-image {
      border-radius: $radius;
      margin: 0 15px 0 0;
      width: 80px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-post {
      font-size: 12px;
      font-style: italic;
      font-weight: normal;
      margin-bottom: 15px;
    }

    &-mail {
      font-size: 10px;
    }

    &-name {
      font-size: 12px;
      margin-top: 15px;
    }

  }

  .Content {
    padding: 0 20px 92px;
  }

  .Infos {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;

    &-item {
      max-width: 100%;
    }
  }

  .Info {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 2;
    transition: transform 600ms;

    &::before {
      border-radius: 12px;
      content: '';
      height: 100%;
      opacity: 0;
      position: absolute;
      transition: opacity 200ms;
      width: 100%;
    }

    &:hover, &:active {
      transform: scale(1.03, 1.03);
      &::before {
        opacity: .2;
      }
    }

    &-button {
      margin-right: 15px;
    }

    &-content {
      font-size: 12px;
      line-height: 13px;
    }

    &-title {
      font-weight: 900;
    }
  }

  .Footer {
    align-items: center;
    display: flex;
    height: 82px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 3;

    &-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
    }

    &-button {
      transition: transform 600ms cubic-bezier(0, 0, 0, 3);

      &:hover, &:active {
        transform: scale(1.2, 1.2);
      }
    }
  }

  .Button {
    border-radius: 12px;
    display: flex;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;

    &-icon {
      width: 21px;
    }
  }

  .Copy {
    background: none;
    border: none;
    cursor: pointer;
    width: 22px;
    transition: transform 600ms;

    &:hover, &:active {
      transform: scale(1.2);
    }
  }

  .Flash {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 600px) {
    .Infos {
      flex-direction: column;

        &-item {
          width: 320px;

          & + & {
          margin-top: 15px;
        }
      }
    }

    .Info {
      &:hover {
        &::before {}
      }
    }
  }

  @media (min-width: 601px) {
    .Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      padding-bottom: 82px;
    }

    .Person {
      width: 100%;
    }

    .Infos {
      align-items: center;
      flex-direction: row;
      flex: 1;
      margin: 0 -10px;

      &-item {
        padding: 10px;
        width: 50%;
        margin-top: -10px;
      }
    }
  }

  @media (min-height: 570px) {
    .Content {
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      padding-bottom: 90px;
    }

    .Infos {
      flex: 1 0 auto;
      justify-content: space-between;
    }
  }

</style>
